import Wrap from '../components/Wrap'
import Button from '../components/Button'
import Menu from '../components/Menu'
import Section from '../components/Section'
import Footer from '../components/Footer'
import Heading from '../components/Heading'
import Intro from '../components/Intro'

export default function Page404() {
  return (
    <>
      <Section large>
        <Menu />
      </Section>
      <Wrap>
        <Section>
          <Heading type={1} size={1} center>
            Pagina niet gevonden
          </Heading>
          <Intro>
            Gebruik het menu om door de website te navigeren of keer terug naar
            de homepagina.
          </Intro>
        </Section>
        <div className="">
          <Button to="/">Naar de homepagina</Button>
        </div>
      </Wrap>
      <Footer />
    </>
  )
}
